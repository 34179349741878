/**
 * 财务报表相关请求
 */
import axios from './http';

// 获取报表统计汇总年月份金额
export const getSummaryPaymentList = (params) => axios.get('/ParkingLotApi/Financial/GetSummaryParkingPayment', { params: params })

// 查看停车场报表统计汇总柱状图
export const getSummaryParkingDetailsMonthHistogramPaymentList = (params) => axios.get('/ParkingLotApi/Financial/GetSummaryParkingDetailsMonthHistogramPaymentList', { params: params })

// 报表汇总柱状图详情
export const getSummaryParkingMonthHistogramPaymentList = (params) => axios.get('/ParkingLotApi/Financial/GetNewSummaryParkingMonthHistogramPaymentList', { params: params })

// 查看年报表统计汇总缴费方式
export const getSummaryParkingPaymentMethondList = (params) => axios.get('ParkingLotApi/Financial/GetSummaryParkingPaymentMethondList', { params: params })

// 巡检人员收费报表
export const getInspectorChargeList = (params) => axios.get('/ParkingLotApi/Financial/ChargeStatistics', { params: params })
// 巡检人员收费报表详情接口
export const getInspectorChargeDetail = (params) => axios.get('/ParkingLotApi/Financial/ChargeDetail', { params: params })
//  获取临时卡、计时卡缴费记录
export const getTimeCardPaymentList = (params) => axios.get('/ParkingLotApi/Financial/GetTimeCardPaymentPageList', { params: params })

// 获取月租卡、储值卡缴费记录
export const getCardPaymentList = (params) => axios.get('/ParkingLotApi/Financial/GetCardPaymentPageList', { params: params })

// 查看停车场临停车缴费报表
export const getTemporaryParkingPaymentList = (params) => axios.get('/ParkingLotApi/Financial/GetTemporaryParkingPaymentList', { params: params })

// 查看停车场临停车缴费柱状图报表
export const getTemporaryParkingHistogramPaymentList = (params) => axios.get('/ParkingLotApi/Financial/GetNewTemporaryParkingHistogramPaymentList', { params: params })

// 临停车柱状图报表详情
export const getTemporaryParkingDetailsOfTheSumPaymentList = (params) => axios.get('/ParkingLotApi/Financial/GetTemporaryParkingDetailsOfTheSumPaymentList', { params: params })

// 查看停车场临停车缴费方式汇总报表
export const getTemporaryParkingPaymentMethondList = (params) => axios.get('/ParkingLotApi/Financial/GetTemporaryParkingPaymentMethondList', { params: params })

// 获取报表统计汇总年月份金额
export const getSummaryPaymentCountList = (params) => axios.get('/ParkingLotApi/Financial/GetSummaryPaymentList', { params: params })

// 查询发票列表
export const getSysInvoiceInfoList = (params) => axios.get('/ParkingLotApi/SysInvoiceInfo/GetPageList', { params: params })

// 发票配置
export const addSysInvoiceConfig = (data) => {
    return axios({
        method: 'post',
        url: '/ParkingLotApi/SysInvoiceConfig/Add',
        data
    })
}

// 获取发票配置接口
export const getSysInvoiceConfigDetail = (params) => axios.get('/ParkingLotApi/SysInvoiceConfig/Detail', { params: params })

// 修改发票配置接口
export const updateSysInvoiceConfig = (data) => {
    return axios({
        method: 'post',
        url: '/ParkingLotApi/SysInvoiceConfig/Update',
        data
    })
}

// 获取发票配置接口
export const getSysInvoiceInfoDetail = (params) => axios.get('/ParkingLotApi/SysInvoiceInfo/Detail', { params: params })

// 根据集团id获取停车场名称接口
export const getParkingByGroupid = (params) => axios.get('/ParkingLotApi/SysInvoiceConfig/GetParking', { params: params })

// 获取未配置发票配置的集团下拉列
export const getNoConfigList = (params) => axios.get('/ParkingLotApi/SysInvoiceConfig/NEGroupList', { params: params })

// 获取已配置发票配置的集团下拉列接口
export const getConfiguredList = (params) => axios.get('/ParkingLotApi/SysInvoiceConfig/GroupList', { params: params })

// 获取发票配置列表接口admin
export const getAdminBillList = (params) => axios.get('/ParkingLotApi/SysInvoiceConfig/GetPageList', { params: params })

// 删除发票配置接口
export const deleteInvoice = (params) => axios.get('/ParkingLotApi/SysInvoiceConfig/Delete', { params: params })
