<template>
  <!-- 财务报表 —— 计时卡缴费记录 -->
  <div class="box-card" style="text-align:left;">
    <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
      @submit.native.prevent>
      <el-form-item label="停车场：">
        <el-select popper-class="my-select" v-model="queryForm.parking_id" filterable clearable default-first-option
          placeholder="请选择停车场" @change="onSubmit">
          <el-option v-for="item in parkingList" :key="item.parking_id" :label="item.parking_name"
            :value="item.parking_id">
          </el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="车位编号：">
            <el-input v-model="queryForm.parking_space_name" @keyup.enter.native="onSubmit()" @input="(e) => (queryForm.parking_space_name = validSpace(e))" placeholder="请输入车位编号"></el-input>
          </el-form-item> -->
      <el-form-item label="车牌号：">
        <el-input v-model="queryForm.plate_number" @keyup.enter.native="onSubmit()"
          @input="(e) => (queryForm.plate_number = validPlatetoUpperCase(e))" placeholder="请输入车牌号"></el-input>
      </el-form-item>
      <el-form-item label="支付方式：">
        <el-select popper-class="my-select" v-model="queryForm.payment_method" placeholder="请选择支付方式" clearable
          @clear="queryForm.payment_method = null" @change="onSubmit">
          <el-option v-for="item in getOptions('PaymentMethod', true)" :key="item.value" :label="item.name"
            :value="item.value"></el-option>
          <!-- <el-option label="微信" :value="1"></el-option>
                <el-option label="支付宝" :value="2"></el-option>
                <el-option label="现金" :value="3"></el-option>
                <el-option label="全部" :value="0"></el-option> -->
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="缴费时间：">
              <el-date-picker v-model="queryForm.start_time" type="datetime" @change="onSubmit" :editable="false" placeholder="请选择开始时间" default-time="00:00:00"></el-date-picker>
              <span style="margin: 0 10px;">~</span>
              <el-date-picker v-model="queryForm.end_time" type="datetime" @change="onSubmit" :editable="false" placeholder="请选择结束时间" default-time="23:59:59"></el-date-picker>
            </el-form-item> -->
      <el-form-item label="缴费时间：">
        <el-date-picker v-model="timeduan" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']"
          @blur="onSubmit" type="datetimerange" range-separator="至" start-placeholder="请选择开始日期" end-placeholder="请选择结束日期">
        </el-date-picker>
        <!-- <el-date-picker v-model="queryForm.start_time" type="datetime" @change="startTimeStatus" :picker-options="pickerOptionsStart" :editable="false" placeholder="请选择开始时间" default-time="00:00:00"></el-date-picker>
              <span style="margin: 0 10px;">~</span>
              <el-date-picker v-model="queryForm.end_time" type="datetime" @change="endTimeStatus" :picker-options="pickerOptionsEnd" :editable="false" placeholder="请选择结束时间" default-time="23:59:59"></el-date-picker> -->
      </el-form-item>
      <el-form-item>
        <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
      </el-form-item>
    </el-form>
    <le-card>
      <template slot="heard-left">
        <leCount :count="total_amount"></leCount>
      </template>
      <template slot="heard-right">
        <div class="right">
          <!-- <el-button  size="small" type="danger"  @click="batchDel" >删除</lbButton> -->
          <lbButton type="warning" icon="daochu" @click="exportFile" v-preventReClick
            v-if="isShowBtn('77345a9206c043e4a946fe67') || isShowBtn('17724a9c3ee5493dab68ab50')">导出</lbButton>
        </div>
      </template>
      <div>
        <!-- 主要内容 -->
        <!-- 表格 -->
         <!-- 使用插件生产表格 -->
         <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @size-change="sizeChange"
            @current-change="currentChange">
            <template slot-scope="{row}" slot="car_owner_card_type">
              {{ getWayName('CarOwnerCardType', row.car_owner_card_type) }}
            </template>
            <template slot-scope="{row}" slot="payment_method">
              {{ getWayName('PaymentMethod', row.payment_method) }}
            </template>
            <template slot-scope="{row}" slot="final_cost">
              ￥{{ row.final_cost.toFixed(2) }}
            </template>
            <template slot-scope="{row}" slot="payment_time">
              {{ $moment(row.payment_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </avue-crud> -->
        <el-table class="tablestyle" ref="multipleTable" :data="tableData" align="center" header-align="center"
          tooltip-effect="dark" style="width: 100%" border stripe>
          <el-table-column prop="plate_number" label="车牌号"></el-table-column>
          <el-table-column prop="parking_name" label="停车场"></el-table-column>
          <el-table-column label="卡类型">
            <template slot-scope="scope">
              {{ getWayName('CarOwnerCardType', scope.row.car_owner_card_type) }}
            </template>
          </el-table-column>
          <el-table-column prop="payment_method" label="支付方式">
            <template slot-scope="scope">
              {{ getWayName('PaymentMethod', scope.row.payment_method) }}
            </template>
          </el-table-column>
          <el-table-column prop="final_cost" label="金额">
            <template slot-scope="scope">
              ￥{{ scope.row.final_cost.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column label="缴费时间">
            <template slot-scope="scope">
              {{ $moment(scope.row.payment_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total" >
        </el-pagination>
      </div>
    </le-card>
  </div>
</template>

<script>
import { getTimeCardPaymentList } from '@/api/financialStatement'
import { mapActions, mapState, mapGetters } from 'vuex'
export default {
  data () {
    return {
      // 查询表单
      queryForm: {
        parking_id: '',
        plate_number: '',
        parking_space_name: '',
        start_time: '',
        end_time: '',
        payment_method: null,
        car_owner_card_type: 1,
        PageSize: 10,
        PageIndex: 1
      },
      timeduan: '',
      total: 0,
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: false,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '车牌号',
            prop: 'plate_number'
          }, {
            label: '停车场',
            prop: 'parking_name'
          }, {
            label: '卡类型',
            prop: 'car_owner_card_type',
            slot: true
          }, {
            label: '支付方式',
            prop: 'payment_method',
            slot: true
          }, {
            label: '金额',
            prop: 'final_cost',
            slot: true
          }, {
            label: '缴费时间',
            prop: 'payment_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      },
      // 表格数据
      tableData: [],
      total_amount: 0,
      pickerOptionsStart: {
        disabledDate: time => {
          const endDateVal = this.queryForm.end_time
          if (endDateVal) {
            return time.getTime() > new Date(endDateVal).getTime()
          }
        }
      },
      pickerOptionsEnd: {
        disabledDate: time => {
          const beginDateVal = this.queryForm.start_time
          if (beginDateVal) {
            return (time.getTime() < new Date(beginDateVal).getTime())
          }
        }
      }
    }
  },
  created () {
    this.fnGetTimeCardPaymentList()
  },
  computed: {
    ...mapState(['parkingList']),
    ...mapGetters(['getOptions', 'getWayName'])
  },
  watch: {
    'timeduan' (newV, oldV) {
      if (newV) {
        this.queryForm.start_time = newV[0]
        this.queryForm.end_time = newV[1]
      } else {
        this.queryForm.start_time = ''
        this.queryForm.end_time = ''
        this.onSubmit()
      }
    }
  },
  mounted () {
  },
  methods: {
    // 时间开始选择器
    startTimeStatus: function (value) {
      this.queryForm.start_time = value
      this.onSubmit()
    },
    // 时间结束选择器
    endTimeStatus: function (value) {
      this.queryForm.end_time = value
      this.onSubmit()
    },
    //  获取临时卡、计时卡缴费记录
    async fnGetTimeCardPaymentList () {
      const res = await getTimeCardPaymentList({
        parking_id: this.queryForm.parking_id,
        plate_number: this.queryForm.plate_number,
        parking_space_name: this.queryForm.parking_space_name,
        start_time: this.queryForm.start_time,
        end_time: this.queryForm.end_time,
        payment_method: this.queryForm.payment_method ? this.queryForm.payment_method : null,
        car_owner_card_type: this.queryForm.car_owner_card_type,
        PageSize: this.queryForm.PageSize,
        PageIndex: this.queryForm.PageIndex
      })
      // console.log(res);
      this.total = res.Data.TotalCount
      this.tableData = res.Data.DataList
      this.total_amount = res.Data.totalCharge
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      if (this.queryForm.end_time || this.queryForm.start_time) {
        if (this.queryForm.end_time) {
          this.queryForm.end_time = this.$moment(this.queryForm.end_time).format('YYYY-MM-DD') + ' 23:59:59'
        }
        if (this.queryForm.start_time) {
          this.queryForm.start_time = this.$moment(this.queryForm.start_time).format('YYYY-MM-DD') + ' 00:00:00'
        }
        this.fnGetTimeCardPaymentList()
      } else {
        this.fnGetTimeCardPaymentList()
      }
    },
    // 每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetTimeCardPaymentList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetTimeCardPaymentList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      this.queryForm.PageIndex = val
      this.fnGetTimeCardPaymentList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0

      // console.log(`当前页: ${val}`);
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetTimeCardPaymentList()
    // },
    // 导出
    async exportFile () {
      const res = await getTimeCardPaymentList({
        parking_id: this.queryForm.parking_id,
        plate_number: this.queryForm.plate_number,
        parking_space_name: this.queryForm.parking_space_name,
        start_time: this.queryForm.start_time,
        end_time: this.queryForm.end_time,
        payment_method: this.queryForm.payment_method,
        car_owner_card_type: this.queryForm.car_owner_card_type,
        PageSize: 0,
        PageIndex: 0
      })
      // console.log(res);
      var DataList = res.Data.DataList
      if (DataList.length <= 0) {
        this.$msg.info('暂无计时卡缴费记录！')
      } else {
        // 设置当前日期
        var name = this.$moment(new Date()).format('YYYY-MM-DD')
        // console.log(name);
        import('@/utils/Export2Excel').then((excel) => {
          const tHeader = [
            '车牌号',
            '停车场',
            '卡类型',
            // "车位编号",
            '支付方式',
            '金额',
            '缴费时间'
          ]
          const filterVal = [
            'plate_number',
            'parking_name',
            'car_owner_card_type',
            // "parking_space_name",
            'payment_method',
            'final_cost',
            'payment_time'
          ]
          const list = DataList
          const data = this.formatJson(filterVal, list)
          // console.log(data);
          excel.export_json_to_excel({
            header: tHeader, //  必填   导出数据的表头
            data, //  必填   导出具体数据
            filename: '计时卡缴费记录' + name, //  非必填   导出文件名
            autoWidth: true, //  非必填   单元格是否自动适应宽度
            bookType: 'xlsx' //  非必填   导出文件类型
          })
        })
      }
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        var map = {}
        this.$set(map, j, v[j])
        return this.formatter(map, { property: j })
        // 为formatter专门为了码表渲染的组件
      })
      )
    },
    // 数据格式化
    formatter (row, column) {
      switch (column.property) {
        case 'payment_time':
          return this.$moment(row.payment_time).format('YYYY-MM-DD HH:mm:ss')
        case 'final_cost':
          return row.final_cost = '￥' + row.final_cost.toFixed(2)
        case 'car_owner_card_type':
          return this.getWayName('CarOwnerCardType', row.car_owner_card_type)
        case 'payment_method':
          return this.getWayName('PaymentMethod', row.payment_method)
        default:
          return row[column.property]
      }
    }
  }
}
</script>

<style scoped lang="less">
.box-card{
  margin-top: 20px;
}
.top_content {
  display: flex;
  justify-content: space-between;

  .right_box {
    width: 34%;
    text-align: right;

    span {
      font-size: 18px;
      font-weight: 600;
      color: #000;
    }
  }
}

.tablestyle {
  /deep/ .el-table__body-wrapper {
    max-height: 510px;
  }
}
/deep/.el-form.query_form.el-form--inline .el-form-item.el-form-item--small .el-form-item__content .el-date-editor{
  width: 350px!important;
}</style>
